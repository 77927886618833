import React, { useState } from "react";
import data from "./assets/data/hospitals-v4.json"
import Hospital from "./components/Hospital"
import Modal from 'react-modal/lib/components/Modal';
import ModalContent from "./components/ModalContent";
import postcodeData from "./assets/data/au_postcodes.json"
import Fuse from 'fuse.js';
import { getDistance } from 'geolib';


function App() {


  //map postcodes
  const postcodes = postcodeData.map(postcode => {
    return {
      "postcode": postcode.postcode,
      "state": postcode.state_code,
      "suburb": postcode.place_name,
      "latitude": postcode.latitude,
      "longitude": postcode.longitude,
    }
  })

  //add "0" in front of NT postcodes
  const fixNtPostcode = () => {
    postcodes.forEach(hospital => {
      if (hospital.state === "NT") {
        hospital.postcode = "0" + hospital.postcode
      }
    })
  }
  fixNtPostcode()

  //clone hospital data
  const originalHospitals = data.map(hospital => {
    return {
      "name": hospital.name,
      "edResuscitationTraffic": hospital.edResuscitationTraffic,
      "edEmergencyTraffic": hospital.edEmergencyTraffic,
      "edUrgentTraffic": hospital.edUrgentTraffic,
      "edSemiUrgentTraffic": hospital.edSemiUrgentTraffic,
      "edNonUrgentTraffic": hospital.edNonUrgentTraffic,
      "urgentElectiveTraffic": hospital.urgentElectiveTraffic,
      "semiUrgentElectiveTraffic": hospital.semiUrgentElectiveTraffic,
      "nonUrgentElectiveSurgeryTraffic": hospital.nonUrgentElectiveSurgeryTraffic,

      "address": hospital.address,

      "edResuscitation": hospital.edResuscitation,
      "edEmergency": hospital.edEmergency,
      "edUrgent": hospital.edUrgent,
      "edSemiUrgent": hospital.edSemiUrgent,
      "edNonUrgent": hospital.edNonUrgent,
      "urgentElectiveSurgery": hospital.urgentElectiveSurgery,
      "semiUrgentElectiveSurgery": hospital.semiUrgentElectiveSurgery,
      "nonUrgentElectiveSurgery": hospital.nonUrgentElectiveSurgery,

      "latitude": hospital.latitude,
      "longditude": hospital.longitude,

      "distance": 0
    }
  })


  //group hospitals by state
  let actHospitals = []
  let nswHospitals = []
  let qldHospitals = []
  let vicHospitals = []
  let tasHospitals = []
  let waHospitals = []
  let ntHospitals = []
  let saHospitals = []
  data.forEach(hospital => {
    if (hospital.address.includes("ACT")) {
      actHospitals.push(hospital)
    } else if (hospital.address.includes("NSW")) {
      nswHospitals.push(hospital)
    } else if (hospital.address.includes("VIC")) {
      vicHospitals.push(hospital)
    } else if (hospital.address.includes("QLD")) {
      qldHospitals.push(hospital)
    } else if (hospital.address.includes("WA")) {
      waHospitals.push(hospital)
    } else if (hospital.address.includes("TAS")) {
      tasHospitals.push(hospital)
    } else if (hospital.address.includes("SA")) {
      saHospitals.push(hospital)
    } else {
      ntHospitals.push(hospital)
    }
  })

  // state declarations
  let [hospitals, setHospitals] = useState(originalHospitals)
  let [userQuery, setUserQuery] = useState(false)
  let [combined, setCombined] = useState([])
  let [query, updateQuery] = useState('')
  let [modalIsOpen, setModalIsOpen] = useState(false)
  let [origin, setOrigin] = useState('')
  let [fuzzyStyles, setFuzzyStyles] = useState({ display: "none" })
  let [coords, setCoords] = useState()

  //styles for modal pop-up
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      height: 'auto',
      top: '88px',
      zIndex: '60'
    },
    content: {
      borderRadius: '24px',
      border: '0px',
      WebkitOverflowScrolling: 'touch',
      minHeight: '500px',
      maxWidth: '500px',
      margin: '0 auto'
    },
  };

  //setup fuse.js
  const fuse = new Fuse(postcodes, {
    minMatchCharLength: 4,
    threshold: 0.1,
    keys: [
      'postcode',
      'suburb'
    ]
  });
  const results = fuse.search(query);
  const fuzzyResults = results.map(result => result.item)

  //find postcode's australian state
  const findPostcodeState = (postcodeQuery) => {
    if (postcodeQuery.includes("ACT")) {
      return "ACT"
    } else if (postcodeQuery.includes("NSW")) {
      return "NSW"
    } else if (postcodeQuery.includes("VIC")) {
      return "VIC"
    } else if (postcodeQuery.includes("QLD")) {
      return "QLD"
    } else if (postcodeQuery.includes("WA")) {
      return "WA"
    } else if (postcodeQuery.includes("NT")) {
      return "NT"
    } else if (postcodeQuery.includes("TAS")) {
      return "TAS"
    } else {
      return "SA"
    }
  }

  //get hospital coords based on state
  const getHospitalCoords = (ausState) => {

    if (ausState === "NSW") {
      const hospitalInfo = nswHospitals.map(hospital => {
        return { name: hospital.name, latitude: hospital.latitude, longitude: hospital.longitude }
      })
      return hospitalInfo
    } else if (ausState === "QLD") {
      const hospitalInfo = qldHospitals.map(hospital => {
        return { name: hospital.name, latitude: hospital.latitude, longitude: hospital.longitude }
      })
      return hospitalInfo
    } else if (ausState === "VIC") {
      const hospitalInfo = vicHospitals.map(hospital => {
        return { name: hospital.name, latitude: hospital.latitude, longitude: hospital.longitude }
      })
      return hospitalInfo
    } else if (ausState === "TAS") {
      const hospitalInfo = tasHospitals.map(hospital => {
        return { name: hospital.name, latitude: hospital.latitude, longitude: hospital.longitude }
      })
      return hospitalInfo
    } else if (ausState === "SA") {
      const hospitalInfo = saHospitals.map(hospital => {
        return { name: hospital.name, latitude: hospital.latitude, longitude: hospital.longitude }
      })
      return hospitalInfo
    } else if (ausState === "NT") {
      const hospitalInfo = ntHospitals.map(hospital => {
        return { name: hospital.name, latitude: hospital.latitude, longitude: hospital.longitude }
      })
      return hospitalInfo
    } else if (ausState === "WA") {
      const hospitalInfo = waHospitals.map(hospital => {
        return { name: hospital.name, latitude: hospital.latitude, longitude: hospital.longitude }
      })
      return hospitalInfo
    } else {
      const hospitalInfo = actHospitals.map(hospital => {
        return { name: hospital.name, latitude: hospital.latitude, longitude: hospital.longitude }
      })
      return hospitalInfo
    }
  }


  //sort the list of distance results
  const sortList = () => {
    combined.sort((a, b) => {
      return a.distance - b.distance
    })
  }

  //checks if the query is empty, if not empty get the hospital distances
  const setDistanceToHospital = () => {
    setCombined([])
    const query = document.getElementById("hospitalSearch").value;
    if (query === "") {
      alert("Please enter a postcode")
    } else {
      const ausState = findPostcodeState(query)
      setUserQuery(true)
      setOrigin(query)
      const hospitalCoords = getHospitalCoords(ausState)
      hospitalCoords.forEach(hospital => {
        setCombined(x => [...x, {
          name: hospital.name,
          distance: getDistance(
            { latitude: coords[0], longitude: coords[1] },
            { latitude: hospital.latitude, longitude: hospital.longitude }
          )
        }])
      })

    }

  }


  //if the user has not made a query, render the original list, if they have, render the sorted result list
  const getList = (original, sorted) => {
    if (!userQuery) {
      return (
        original.map(hospital => {
          return (
            <Hospital name={hospital.name} list={original} searched={false} />
          )
        })
      )

    } else {
      return (
        sorted.map((hospital, index) => {
          if (index === 0 || index === 1 || index === 2) {
            return <Hospital name={hospital.name} distance={Math.floor(hospital.distance * 0.001) + " km"} list={original} searched={true} />
          }
        }

        ))
    }

  }

  const getPostcode = () => {
    if (combined.length > 1) {
      return (
        <div className='flex'>
          <svg className='mr-1' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 1.66666C6.50001 1.66666 3.33334 4.34999 3.33334 8.49999C3.33334 11.15 5.37501 14.2667 9.45001 17.8583C9.76668 18.1333 10.2417 18.1333 10.5583 17.8583C14.625 14.2667 16.6667 11.15 16.6667 8.49999C16.6667 4.34999 13.5 1.66666 10 1.66666ZM10 9.99999C9.08334 9.99999 8.33334 9.24999 8.33334 8.33332C8.33334 7.41666 9.08334 6.66666 10 6.66666C10.9167 6.66666 11.6667 7.41666 11.6667 8.33332C11.6667 9.24999 10.9167 9.99999 10 9.99999Z" fill="#000000" />
          </svg>
          <p className='text-sm text-black'>{origin}</p>
        </div>
      )
    } else {
      return (
        <div className='flex'>
          <svg className='mr-1' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 1.66666C6.50001 1.66666 3.33334 4.34999 3.33334 8.49999C3.33334 11.15 5.37501 14.2667 9.45001 17.8583C9.76668 18.1333 10.2417 18.1333 10.5583 17.8583C14.625 14.2667 16.6667 11.15 16.6667 8.49999C16.6667 4.34999 13.5 1.66666 10 1.66666ZM10 9.99999C9.08334 9.99999 8.33334 9.24999 8.33334 8.33332C8.33334 7.41666 9.08334 6.66666 10 6.66666C10.9167 6.66666 11.6667 7.41666 11.6667 8.33332C11.6667 9.24999 10.9167 9.99999 10 9.99999Z" fill="#D1D5DB" />
          </svg>
          <p className='text-sm text-gray-300'>Enter postcode</p>
        </div>
      )
    }
  }

  const handleResetButton = () => {
    setCombined([])
    setHospitals(originalHospitals)
    setUserQuery(false)
    setOrigin('')
    setFuzzyStyles({ display: "none" })
    document.getElementById("hospitalSearch").value = ""
  }

  sortList()

  //handle the styles for the fuzzy search
  const onSearch = () => {
    const userPostcode = document.getElementById('hospitalSearch').value
    if (userPostcode.length === 0) {
      setFuzzyStyles({ display: "none" })
    } else if (userPostcode.length > 1) {
      setFuzzyStyles({
        display: 'flex',
        flexDirection: 'column',
        height: 'fit-content',
        maxHeight: '10rem',
        width: '100%',
        overflow: 'auto',
        position: 'absolute',
        padding: '1rem',
        zIndex: '55',
      })
      updateQuery(userPostcode);
    }
  }

  //set the origin coords from query
  const setUserPostcode = (result) => {
    document.getElementById('hospitalSearch').value = result.suburb + ", " + result.state + ", " + result.postcode
    setCoords([result.latitude, result.longitude])
    setFuzzyStyles({ display: "none" })
  }

  return (
    <div className="App bg-white space-y-3">
      <div className="flex justify-between">
        <p className="font-bold text-lg">Hospital logjam finder</p>
        <p>Is your local hospital in logjam?</p>
      </div>
      <div className="relative">
        <input id="hospitalSearch" type="text" placeholder="Enter your postcode" onChange={onSearch} max="9999" className="border-2 rounded-xl p-3 w-full" />
        <ul id="fuzzyResults" className="bg-white text-black shadow-lg fuzzyResults" style={fuzzyStyles}>
          {fuzzyResults.map((result, index) => {
            return (
              <div>
                <button className="p-2 w-full text-left" id={index + result.suburb} onClick={() => setUserPostcode(result)}>{result.suburb} {result.state} {result.postcode}</button>
              </div>
            )
          })}
        </ul>
      </div>
      <div className="flex space-x-3">
        <button type="button" onClick={handleResetButton} className="grow px-4 rounded-xl text-black border border-gray-700">Reset</button>
        <button type="button" onClick={setDistanceToHospital} className="grow bg-blue-ama p-2 rounded-xl text-white">Search</button>

      </div>
      <div className='flex justify-between items-center px-3'>
        <div>
          <button onClick={() => setModalIsOpen(true)} className='text-sm font-medium text-gray-400'>Details about this data</button>
        </div>
        <Modal
          isOpen={modalIsOpen}
          shouldCloseOnOverlayClick={false}
          onRequestClose={() => setModalIsOpen(false)}
          contentLabel="Hospital logjam – what does it mean for you – the patient? "
          style={customStyles}
        >
          <div>
            <button onClick={() => setModalIsOpen(false)} className="flex p-2 border rounded-full"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.3 5.70998C18.2075 5.61728 18.0976 5.54373 17.9766 5.49355C17.8557 5.44337 17.726 5.41754 17.595 5.41754C17.464 5.41754 17.3344 5.44337 17.2134 5.49355C17.0924 5.54373 16.9825 5.61728 16.89 5.70998L12 10.59L7.11001 5.69998C7.01743 5.6074 6.90752 5.53396 6.78655 5.48385C6.66559 5.43375 6.53594 5.40796 6.40501 5.40796C6.27408 5.40796 6.14443 5.43375 6.02347 5.48385C5.9025 5.53396 5.79259 5.6074 5.70001 5.69998C5.60743 5.79256 5.53399 5.90247 5.48388 6.02344C5.43378 6.1444 5.40799 6.27405 5.40799 6.40498C5.40799 6.53591 5.43378 6.66556 5.48388 6.78652C5.53399 6.90749 5.60743 7.0174 5.70001 7.10998L10.59 12L5.70001 16.89C5.60743 16.9826 5.53399 17.0925 5.48388 17.2134C5.43378 17.3344 5.40799 17.464 5.40799 17.595C5.40799 17.7259 5.43378 17.8556 5.48388 17.9765C5.53399 18.0975 5.60743 18.2074 5.70001 18.3C5.79259 18.3926 5.9025 18.466 6.02347 18.5161C6.14443 18.5662 6.27408 18.592 6.40501 18.592C6.53594 18.592 6.66559 18.5662 6.78655 18.5161C6.90752 18.466 7.01743 18.3926 7.11001 18.3L12 13.41L16.89 18.3C16.9826 18.3926 17.0925 18.466 17.2135 18.5161C17.3344 18.5662 17.4641 18.592 17.595 18.592C17.7259 18.592 17.8556 18.5662 17.9766 18.5161C18.0975 18.466 18.2074 18.3926 18.3 18.3C18.3926 18.2074 18.466 18.0975 18.5161 17.9765C18.5662 17.8556 18.592 17.7259 18.592 17.595C18.592 17.464 18.5662 17.3344 18.5161 17.2134C18.466 17.0925 18.3926 16.9826 18.3 16.89L13.41 12L18.3 7.10998C18.68 6.72998 18.68 6.08998 18.3 5.70998V5.70998Z" fill="black" />
            </svg>
              Close</button>
            <ModalContent />
          </div>

        </Modal>
        {getPostcode()}
      </div>
      <div className='max-h-96 overflow-auto flex flex-col space-y-4 relative'>
        {getList(hospitals, combined)}
      </div>
    </div>
  );
}

export default App;
