function ModalContent() {
  return (
    <div>
      <h3 className="font-bold mt-3 text-xl">Details about this data</h3>
      <br />
      <p className='text-sm sm:text-base'>Data on performance has been sourced from the AIHW MyHospitals information portal. The information has been downloaded from this portal for each public hospital across Australia.</p>
      <br />
      <p className='text-sm sm:text-base'>Two performance categories were used for all hospitals to assess hospital performance: </p>
      <ul className='list-disc ml-5 text-sm sm:text-base'>
        <li>Emergency department performance: the percentage of patients who commenced treatment within the recommended time for each urgency category: </li>
        <ul className='list-disc ml-5 text-sm sm:text-base'>
          <li>Resuscitation (Triage Category 1): requires treatment immediately</li>
          <li>Emergency (Triage Category 2): requires treatment within 10 minutes</li>
          <li>Urgent (Triage Category 3): requires treatment within 30 minutes</li>
          <li>Semi-urgent (Triage Category 4): requires treatment within 1 hour</li>
          <li>Non-urgent (Triage Category 5): requires treatment within 2 hours</li>
        </ul>
        <li>Elective surgery: the percentage of patients who received their surgery within the clinically recommended time for each urgency category:</li>
        <ul className='list-disc ml-5 text-sm sm:text-base'>
          <li>Urgent (Category 1): Needing treatment within 30 days.</li>
          <li>Semi-urgent (Category 2): Needing treatment within 90 days.</li>
          <li>Non-urgent (Category 3): Needing treatment within 365 days.</li>
        </ul>
      </ul>
      <br />
      <p className='text-sm sm:text-base'>For each performance metric, hospitals were given a “traffic light” rating: </p>

      <div className="flex mt-3">
        <svg className="mr-2" width="128" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#43A047" stroke="#212121" stroke-width="2" stroke-linecap="round" />
          <path d="M8 9.05V8.95" stroke="#212121" stroke-width="2" stroke-linecap="round" />
          <path d="M16 9.05V8.95" stroke="#212121" stroke-width="2" stroke-linecap="round" />
          <path d="M16 14C15.5 15.5 14.21 17 12 17C9.79 17 8.5 15.5 8 14" stroke="#212121" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <p className='text-sm sm:text-base'>= where performance was 95% or higher (i.e. 95% of patients received care within the clinically recommended time)</p>
      </div>

      <div className="flex">
        <svg className="mr-2" width="190" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#FDD835" stroke="#212121" stroke-width="2" stroke-linecap="round" />
          <path d="M8 9.05V8.95" stroke="#212121" stroke-width="2" stroke-linecap="round" />
          <path d="M16 9.05V8.95" stroke="#212121" stroke-width="2" stroke-linecap="round" />
          <path d="M8 14H16" stroke="#212121" stroke-width="2" stroke-linecap="round" />
        </svg>
        <p className='text-sm sm:text-base'>= where performance was unknown or between 85% and 94% (i.e. 85-94% per cent of patients received care within the clinically recommended time) </p>
      </div>

      <div className="flex">
        <svg className="mr-2" width="140" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#E53935" stroke="#212121" stroke-width="2" stroke-linecap="round" />
          <path d="M8 9.05V8.95" stroke="#212121" stroke-width="2" stroke-linecap="round" />
          <path d="M16 9.05V8.95" stroke="#212121" stroke-width="2" stroke-linecap="round" />
          <path d="M16 16C15.5 14.5 14.21 13 12 13C9.79 13 8.5 14.5 8 16" stroke="#212121" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <p className='text-sm sm:text-base'>= where performance was 84% or below (i.e. less than 84% of patients received care within the clinically recommended time)</p>
      </div>

      <br />
      <p className='text-sm sm:text-base'>All effort has been made to ensure correct representation of the data. The AMA has not altered the reported figures for any hospital. Hospitals without sufficient data to report, or hospitals which do not report the performance data on the same basis as other hospitals were excluded. Where this occurs, numerical values were replaced with a “0” and given a traffic light analysis of “Amber” to indicate caution, as performance was unable to be ascertained.  </p>

      <br />
      <p className="text-sm sm:text-base">It should also be noted that the elective surgery waiting list in regional hospitals does not capture all surgery, as many surgeries are undertaken at major hospitals. For regional areas, the examination of on-time performance should also consider the closest major hospital.</p>
    </div>
  )
}

export default ModalContent;