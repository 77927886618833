function Hospital(props) {

    const name = props.name
    const distance = props.distance
    const list = props.list  
    const searched = props.searched

    const clipStyle = {
        marginBottom: '-24',
        zIndex: '40'
    }

    const dataTest = list.filter(hospital => hospital.name === name)
    const hospitalName = dataTest.map(values => values.name)
    const edResuscitationTraffic = dataTest.map(values => values.edResuscitationTraffic)
    const edEmergencyTraffic = dataTest.map(values => values.edEmergencyTraffic)
    const edUrgentTraffic = dataTest.map(values => values.edUrgentTraffic)
    const edSemiUrgentTraffic = dataTest.map(values => values.edSemiUrgentTraffic)
    const edNonUrgentTraffic = dataTest.map(values => values.edNonUrgentTraffic)
    const urgentElectiveTraffic = dataTest.map(values => values.urgentElectiveTraffic)
    const semiUrgentElectiveTraffic = dataTest.map(values => values.semiUrgentElectiveTraffic)
    const nonUrgentElectiveSurgeryTraffic = dataTest.map(values => values.nonUrgentElectiveSurgeryTraffic)

    const edResuscitation = dataTest.map(values => values.edResuscitation)
    const edEmergency = dataTest.map(values => values.edEmergency)
    const edUrgent = dataTest.map(values => values.edUrgent)
    const edSemiUrgent = dataTest.map(values => values.edSemiUrgent)
    const edNonUrgent = dataTest.map(values => values.edNonUrgent)
    const urgentElective = dataTest.map(values => values.urgentElectiveSurgery)
    const semiUrgentElective = dataTest.map(values => values.semiUrgentElectiveSurgery)
    const nonUrgentElectiveSurgery = dataTest.map(values => values.nonUrgentElectiveSurgery)

    const checkSearched = () => {
        if (searched) {
            return <a href="#contact-local-mp" className="w-full text-center border-2 rounded-xl py-3">Worried? Let your local MP know</a>
        } 
    }


    function checkFace(traffic) {
        if (traffic[0] === "Red") {
            return <svg className="mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#E53935" stroke="#212121" strokeWidth="2" strokeLinecap="round" />
                <path d="M8 9.05V8.95" stroke="#212121" strokeWidth="2" strokeLinecap="round" />
                <path d="M16 9.05V8.95" stroke="#212121" strokeWidth="2" strokeLinecap="round" />
                <path d="M16 16C15.5 14.5 14.21 13 12 13C9.79 13 8.5 14.5 8 16" stroke="#212121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        } else if (traffic[0] === "Amber") {
            return <svg className="mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#FDD835" stroke="#212121" strokeWidth="2" strokeLinecap="round" />
                <path d="M8 9.05V8.95" stroke="#212121" strokeWidth="2" strokeLinecap="round" />
                <path d="M16 9.05V8.95" stroke="#212121" strokeWidth="2" strokeLinecap="round" />
                <path d="M8 14H16" stroke="#212121" strokeWidth="2" strokeLinecap="round" />
            </svg>
        } else {
            return <svg className="mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#43A047" stroke="#212121" strokeWidth="2" strokeLinecap="round" />
                <path d="M8 9.05V8.95" stroke="#212121" strokeWidth="2" strokeLinecap="round" />
                <path d="M16 9.05V8.95" stroke="#212121" strokeWidth="2" strokeLinecap="round" />
                <path d="M16 14C15.5 15.5 14.21 17 12 17C9.79 17 8.5 15.5 8 14" stroke="#212121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        }
    }


    return (
        <div className='flex flex-col items-center'>
            <svg width="194" height="59" style={clipStyle} viewBox="0 0 194 59" fill="none" xmlns="http://www.w3.org/2000/svg" className=''>
                <mask id="path-1-inside-1_1076_577" fill="white">
                    <path fillRule="evenodd" clipRule="evenodd" d="M48 0C42.4772 0 38 4.47715 38 10V26H0V59H194V26H156V10C156 4.47715 151.523 0 146 0H48Z" />
                </mask>
                <path fillRule="evenodd" clipRule="evenodd" d="M48 0C42.4772 0 38 4.47715 38 10V26H0V59H194V26H156V10C156 4.47715 151.523 0 146 0H48Z" fill="#C4C4C4" fillOpacity="0.2" />
                <path d="M38 26V27H39V26H38ZM0 26V25H-1V26H0ZM0 59H-1V60H0V59ZM194 59V60H195V59H194ZM194 26H195V25H194V26ZM156 26H155V27H156V26ZM39 10C39 5.02944 43.0294 1 48 1V-1C41.9249 -1 37 3.92487 37 10H39ZM39 26V10H37V26H39ZM0 27H38V25H0V27ZM1 59V26H-1V59H1ZM194 58H0V60H194V58ZM193 26V59H195V26H193ZM156 27H194V25H156V27ZM155 10V26H157V10H155ZM146 1C150.971 1 155 5.02944 155 10H157C157 3.92487 152.075 -1 146 -1V1ZM48 1H146V-1H48V1Z" fill="black" fillOpacity="0.15" mask="url(#path-1-inside-1_1076_577)" />
            </svg>
            <div className="bg-grey rounded-xl p-4 pt-8 flex flex-col space-y-3 w-full">
                <div className='flex justify-between'>
                    <h2 className='font-bold text-left text-lg'>{hospitalName}</h2>
                    <p>{distance}</p>
                </div>
                <div className='max-h-96 overflow-auto flex flex-col space-y-4'>
                    <div className="flex space-x-4">
                        <div className='flex flex-col space-y-3 grow'>
                            <h2 className="font-medium text-left">Emergency department</h2>
                            <p className="flex">{checkFace(edResuscitationTraffic)} {edResuscitation + "% -" } Resuscitation</p>
                            <p className="flex">{checkFace(edEmergencyTraffic)} {edEmergency + "% -" } Emergency</p>
                            <p className="flex">{checkFace(edUrgentTraffic)} {edUrgent + "% -"} Urgent</p>
                            <p className="flex">{checkFace(edSemiUrgentTraffic)} {edSemiUrgent + "% -"} Semi-urgent</p>
                            <p className="flex">{checkFace(edNonUrgentTraffic)} {edNonUrgent + "% -"} Non-urgent</p>
                        </div>
                        <div className='flex flex-col space-y-3 grow'>
                            <h2 className="font-medium text-left">Elective surgery</h2>
                            <p className="flex">{checkFace(urgentElectiveTraffic)} {urgentElective  + "% -"} Urgent</p>
                            <p className="flex">{checkFace(semiUrgentElectiveTraffic) } {semiUrgentElective  + "% -"} Semi-urgent</p>
                            <p className="flex">{checkFace(nonUrgentElectiveSurgeryTraffic) } {nonUrgentElectiveSurgery + "% -"} Non-urgent</p>
                        </div>
                    </div>
                </div>
                {checkSearched()}
            </div>
            
        </div>
    )

} 

export default Hospital